import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const TubList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: stretch;
  list-style-type: none;
  padding: 0;
  margin: 0;

  ${({ boxDesignMobile }) => {
    if (boxDesignMobile === 'normal') {
      return `
            @media (max-width: 500px) {
                gap: 8px;
            }
            `;
    }
  }}
`;

export const TubListItem = styled.div`
  display: block;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 0;
  padding: 0;
  cursor: pointer;

  &.active > div {
    opacity: 1;

    & img {
      opacity: 1;
    }
  }

  &:hover img {
    opacity: 1;
  }

  & > div {
    height: 100%;

    & > div {
      height: 100%;
    }
  }

  &.current > div > div {
    border: 1px solid #e26447;
    box-shadow: 0 0 5px 0 #e26447;
    opacity: 1;

    ${({ pageDesign }) => {
      switch (pageDesign) {
        case 'design2':
          return `
                  background: #F8EAEC 0% 0% no-repeat padding-box;
                  border: 1px solid #CD626E;
                  box-shadow:none;

                  & > span {
                     opacity: 1;
                  }
                    `;
        default:
          return ``;
      }
    }}

    ${({ styles }) => {
      switch (styles) {
        case 'halloween':
          return `
                  background: #FEF0E6 0% 0% no-repeat padding-box;
                  border: 1px solid #F06400;
                  box-shadow:none;

                  & > span {
                     opacity: 1;
                  }
                    `;
        default:
          return ``;
      }
    }}
  }

  ${({ boxDesign, tubsLength }) => {
    if (boxDesign === `one-row`) {
      return `
                flex: 0 0 ${100 / tubsLength}%;
                @media (max-width: 500px) {
                    display:flex;
                    flex: 0 0 ${100 / tubsLength}%;
                }
            `;
    } else {
      return `
            flex: 0 0 25%;
            `;
    }
  }}

  ${({ boxDesignMobile }) => {
    if (boxDesignMobile === 'normal') {
      return `
            @media (max-width: 500px) {
                flex: 0 calc(50% - 4px);   
            }
            `;
    }
  }}

  ${({ showChildren, childCount }) => {
    if (showChildren)
      return `
            flex: 0 0 ${100 / childCount}%;
        `;
    return ``;
  }}
`;

export const TubListItemContainer = styled.div`
  text-align: center;
  font-size: 16px;
  line-height: 19px;
  padding: 0 5px;
  position: relative;

  & img {
    opacity: 0.7;

    &:hover {
      opacity: 1;
    }

    width: initial;
  }

  @media (max-width: 500px) {
    width: 100%;
    padding: ${({ boxDesignMobile }) =>
      boxDesignMobile === 'normal' ? '0' : '0 5px'};
  }

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    border-radius: 4px;
    padding: 12px 0;
    border: 1px solid
      ${({ pageDesign }) => (pageDesign === `design2` ? '#CCD2E3' : '#dbdbdb')};
    color: ${({ pageDesign }) =>
      pageDesign === `design2` ? '#001C72' : '#213f4e'};

    ${({ backgroundColor }) => {
      if (backgroundColor && backgroundColor.trim().length > 0) {
        return `background-color: ${backgroundColor};`;
      }
    }}
    ${({ pageDesign }) => {
      if (pageDesign === `design2`) {
        return `
            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
            @media (max-width: 500px) {
                opacity: 1;
                & > span {
                    opacity: 0.60;
                }
            }
            
            @media (max-width: 400px) {
                font-size: 15px
            }
            `;
      }
    }}
    & img {
      display: block;
      transition: all 0.2s;
      margin: 0 auto;
      ${({ pageDesign, imageHeight }) => {
        switch (pageDesign) {
          case 'design1':
            return `height: 38px; `;
          default:
            return `height: ${imageHeight || '58'}px; `;
        }
      }}
    }

    & span {
      font-weight: ${({ pageDesign }) =>
        pageDesign === `design2` ? 'normal' : '600'};
      margin-top: 7px;
      display: block;
      white-space: nowrap;
    }

    ${({ boxDesign }) => {
      if (boxDesign === `one-row`) {
        return `
                width: 100%!important;
            `;
      }
    }}
  }
`;

export const Badge = styled.img`
  position: absolute;
  z-index: 3;
  width: 40px;
  height: 40px;
  top: 6px;
  right: 12px;

  @media (max-width: 500px) {
    width: 32px;
    height: 32px;
    top: 1px;
    right: 6px;
  }
`;

export const Price = styled.div``;
